import { trigger, transition, style, animate } from '@angular/animations';
import * as i2 from '@angular/common';
import { isPlatformBrowser, DOCUMENT, CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { PLATFORM_ID, Pipe, Inject, EventEmitter, forwardRef, Component, ViewEncapsulation, Input, Output, computed, signal, booleanAttribute, numberAttribute, ChangeDetectionStrategy, ViewChild, ContentChildren, NgModule } from '@angular/core';
import * as i3 from '@angular/router';
import { RouterModule } from '@angular/router';
import * as i5 from 'primeng/api';
import { PrimeTemplate, SharedModule } from 'primeng/api';
import { DomHandler, ConnectedOverlayScrollHandler } from 'primeng/dom';
import * as i4 from 'primeng/ripple';
import { RippleModule } from 'primeng/ripple';
import * as i6 from 'primeng/tooltip';
import { TooltipModule } from 'primeng/tooltip';
import { UniqueComponentId, ZIndexUtils } from 'primeng/utils';
import * as i1 from '@angular/platform-browser';
const _c0 = ["pMenuItemContent", ""];
function MenuItemContent_ng_container_1_a_1_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
const _c1 = a0 => ({
  "p-disabled": a0
});
const _c2 = a0 => ({
  $implicit: a0
});
function MenuItemContent_ng_container_1_a_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "a", 5);
    i0.ɵɵtemplate(1, MenuItemContent_ng_container_1_a_1_ng_container_1_Template, 1, 0, "ng-container", 6);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r4 = i0.ɵɵnextContext(2);
    const _r3 = i0.ɵɵreference(4);
    i0.ɵɵproperty("target", ctx_r4.item.target)("ngClass", i0.ɵɵpureFunction1(9, _c1, ctx_r4.item.disabled));
    i0.ɵɵattribute("title", ctx_r4.item.title)("href", ctx_r4.item.url || null, i0.ɵɵsanitizeUrl)("data-automationid", ctx_r4.item.automationId)("tabindex", -1)("data-pc-section", "action");
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngTemplateOutlet", _r3)("ngTemplateOutletContext", i0.ɵɵpureFunction1(11, _c2, ctx_r4.item));
  }
}
function MenuItemContent_ng_container_1_a_2_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
const _c3 = () => ({
  exact: false
});
function MenuItemContent_ng_container_1_a_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "a", 7);
    i0.ɵɵtemplate(1, MenuItemContent_ng_container_1_a_2_ng_container_1_Template, 1, 0, "ng-container", 6);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r5 = i0.ɵɵnextContext(2);
    const _r3 = i0.ɵɵreference(4);
    i0.ɵɵproperty("routerLink", ctx_r5.item.routerLink)("queryParams", ctx_r5.item.queryParams)("routerLinkActiveOptions", ctx_r5.item.routerLinkActiveOptions || i0.ɵɵpureFunction0(17, _c3))("target", ctx_r5.item.target)("ngClass", i0.ɵɵpureFunction1(18, _c1, ctx_r5.item.disabled))("fragment", ctx_r5.item.fragment)("queryParamsHandling", ctx_r5.item.queryParamsHandling)("preserveFragment", ctx_r5.item.preserveFragment)("skipLocationChange", ctx_r5.item.skipLocationChange)("replaceUrl", ctx_r5.item.replaceUrl)("state", ctx_r5.item.state);
    i0.ɵɵattribute("data-automationid", ctx_r5.item.automationId)("tabindex", -1)("data-pc-section", "action")("title", ctx_r5.item.title);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngTemplateOutlet", _r3)("ngTemplateOutletContext", i0.ɵɵpureFunction1(20, _c2, ctx_r5.item));
  }
}
function MenuItemContent_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, MenuItemContent_ng_container_1_a_1_Template, 2, 13, "a", 3)(2, MenuItemContent_ng_container_1_a_2_Template, 2, 22, "a", 4);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", !(ctx_r0.item == null ? null : ctx_r0.item.routerLink));
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r0.item == null ? null : ctx_r0.item.routerLink);
  }
}
function MenuItemContent_ng_container_2_1_ng_template_0_Template(rf, ctx) {}
function MenuItemContent_ng_container_2_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, MenuItemContent_ng_container_2_1_ng_template_0_Template, 0, 0, "ng-template");
  }
}
function MenuItemContent_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, MenuItemContent_ng_container_2_1_Template, 1, 0, null, 6);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.itemTemplate)("ngTemplateOutletContext", i0.ɵɵpureFunction1(2, _c2, ctx_r1.item));
  }
}
function MenuItemContent_ng_template_3_span_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 12);
  }
  if (rf & 2) {
    const ctx_r10 = i0.ɵɵnextContext(2);
    i0.ɵɵclassMap(ctx_r10.item.iconClass);
    i0.ɵɵproperty("ngClass", ctx_r10.item.icon)("ngStyle", ctx_r10.item.iconStyle);
  }
}
function MenuItemContent_ng_template_3_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 13);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r11 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(ctx_r11.item.label);
  }
}
function MenuItemContent_ng_template_3_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 14);
    i0.ɵɵpipe(1, "safeHtml");
  }
  if (rf & 2) {
    const ctx_r12 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("innerHTML", i0.ɵɵpipeBind1(1, 1, ctx_r12.item.label), i0.ɵɵsanitizeHtml);
  }
}
function MenuItemContent_ng_template_3_span_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 15);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r14 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngClass", ctx_r14.item.badgeStyleClass);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(ctx_r14.item.badge);
  }
}
function MenuItemContent_ng_template_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, MenuItemContent_ng_template_3_span_0_Template, 1, 4, "span", 8)(1, MenuItemContent_ng_template_3_span_1_Template, 2, 1, "span", 9)(2, MenuItemContent_ng_template_3_ng_template_2_Template, 2, 3, "ng-template", null, 10, i0.ɵɵtemplateRefExtractor)(4, MenuItemContent_ng_template_3_span_4_Template, 2, 2, "span", 11);
  }
  if (rf & 2) {
    const _r13 = i0.ɵɵreference(3);
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngIf", ctx_r2.item.icon);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r2.item.escape !== false)("ngIfElse", _r13);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("ngIf", ctx_r2.item.badge);
  }
}
const _c4 = ["list"];
const _c5 = ["container"];
function Menu_div_0_div_2_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function Menu_div_0_div_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 8);
    i0.ɵɵtemplate(1, Menu_div_0_div_2_ng_container_1_Template, 1, 0, "ng-container", 9);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵattribute("data-pc-section", "start");
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r2.startTemplate);
  }
}
const _c6 = a0 => ({
  "p-hidden": a0
});
function Menu_div_0_5_ng_template_0_li_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "li", 13);
  }
  if (rf & 2) {
    const submenu_r9 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(1, _c6, submenu_r9.visible === false));
  }
}
function Menu_div_0_5_ng_template_0_li_1_ng_container_1_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const submenu_r9 = i0.ɵɵnextContext(3).$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(submenu_r9.label);
  }
}
function Menu_div_0_5_ng_template_0_li_1_ng_container_1_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 18);
    i0.ɵɵpipe(1, "safeHtml");
  }
  if (rf & 2) {
    const submenu_r9 = i0.ɵɵnextContext(3).$implicit;
    i0.ɵɵproperty("innerHTML", i0.ɵɵpipeBind1(1, 1, submenu_r9.label), i0.ɵɵsanitizeHtml);
  }
}
function Menu_div_0_5_ng_template_0_li_1_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, Menu_div_0_5_ng_template_0_li_1_ng_container_1_span_1_Template, 2, 1, "span", 16)(2, Menu_div_0_5_ng_template_0_li_1_ng_container_1_ng_template_2_Template, 2, 3, "ng-template", null, 17, i0.ɵɵtemplateRefExtractor);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const _r19 = i0.ɵɵreference(3);
    const submenu_r9 = i0.ɵɵnextContext(2).$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", submenu_r9.escape !== false)("ngIfElse", _r19);
  }
}
function Menu_div_0_5_ng_template_0_li_1_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
const _c7 = (a0, a1) => ({
  "p-hidden": a0,
  flex: a1
});
function Menu_div_0_5_ng_template_0_li_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "li", 14);
    i0.ɵɵtemplate(1, Menu_div_0_5_ng_template_0_li_1_ng_container_1_Template, 4, 2, "ng-container", 6)(2, Menu_div_0_5_ng_template_0_li_1_ng_container_2_Template, 1, 0, "ng-container", 15);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r23 = i0.ɵɵnextContext();
    const submenu_r9 = ctx_r23.$implicit;
    const i_r10 = ctx_r23.index;
    const ctx_r12 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction2(7, _c7, submenu_r9.visible === false, submenu_r9.visible))("tooltipOptions", submenu_r9.tooltipOptions);
    i0.ɵɵattribute("data-automationid", submenu_r9.automationId)("id", ctx_r12.menuitemId(submenu_r9, ctx_r12.id, i_r10));
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", !ctx_r12.submenuHeaderTemplate);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r12.submenuHeaderTemplate)("ngTemplateOutletContext", i0.ɵɵpureFunction1(10, _c2, submenu_r9));
  }
}
function Menu_div_0_5_ng_template_0_ng_template_2_li_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "li", 13);
  }
  if (rf & 2) {
    const item_r24 = i0.ɵɵnextContext().$implicit;
    const submenu_r9 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(1, _c6, item_r24.visible === false || submenu_r9.visible === false));
  }
}
const _c8 = (a0, a1, a2) => ({
  "p-hidden": a0,
  "p-focus": a1,
  "p-disabled": a2
});
function Menu_div_0_5_ng_template_0_ng_template_2_li_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r32 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "li", 20);
    i0.ɵɵlistener("onMenuItemClick", function Menu_div_0_5_ng_template_0_ng_template_2_li_1_Template_li_onMenuItemClick_0_listener($event) {
      i0.ɵɵrestoreView(_r32);
      const ctx_r31 = i0.ɵɵnextContext();
      const item_r24 = ctx_r31.$implicit;
      const j_r25 = ctx_r31.index;
      const i_r10 = i0.ɵɵnextContext().index;
      const ctx_r30 = i0.ɵɵnextContext(3);
      return i0.ɵɵresetView(ctx_r30.itemClick($event, ctx_r30.menuitemId(item_r24, ctx_r30.id, i_r10, j_r25)));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r34 = i0.ɵɵnextContext();
    const item_r24 = ctx_r34.$implicit;
    const j_r25 = ctx_r34.index;
    const ctx_r35 = i0.ɵɵnextContext();
    const submenu_r9 = ctx_r35.$implicit;
    const i_r10 = ctx_r35.index;
    const ctx_r27 = i0.ɵɵnextContext(3);
    i0.ɵɵclassMap(item_r24.styleClass);
    i0.ɵɵproperty("pMenuItemContent", item_r24)("itemTemplate", ctx_r27.itemTemplate)("ngClass", i0.ɵɵpureFunction3(13, _c8, item_r24.visible === false || submenu_r9.visible === false, ctx_r27.focusedOptionId() && ctx_r27.menuitemId(item_r24, ctx_r27.id, i_r10, j_r25) === ctx_r27.focusedOptionId(), ctx_r27.disabled(item_r24.disabled)))("ngStyle", item_r24.style)("tooltipOptions", item_r24.tooltipOptions);
    i0.ɵɵattribute("data-pc-section", "menuitem")("aria-label", ctx_r27.label(item_r24.label))("data-p-focused", ctx_r27.isItemFocused(ctx_r27.menuitemId(item_r24, ctx_r27.id, i_r10, j_r25)))("data-p-disabled", ctx_r27.disabled(item_r24.disabled))("aria-disabled", ctx_r27.disabled(item_r24.disabled))("id", ctx_r27.menuitemId(item_r24, ctx_r27.id, i_r10, j_r25));
  }
}
function Menu_div_0_5_ng_template_0_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Menu_div_0_5_ng_template_0_ng_template_2_li_0_Template, 1, 3, "li", 11)(1, Menu_div_0_5_ng_template_0_ng_template_2_li_1_Template, 1, 17, "li", 19);
  }
  if (rf & 2) {
    const item_r24 = ctx.$implicit;
    i0.ɵɵproperty("ngIf", item_r24.separator);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", !item_r24.separator);
  }
}
function Menu_div_0_5_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Menu_div_0_5_ng_template_0_li_0_Template, 1, 3, "li", 11)(1, Menu_div_0_5_ng_template_0_li_1_Template, 3, 12, "li", 12)(2, Menu_div_0_5_ng_template_0_ng_template_2_Template, 2, 2, "ng-template", 10);
  }
  if (rf & 2) {
    const submenu_r9 = ctx.$implicit;
    i0.ɵɵproperty("ngIf", submenu_r9.separator);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", !submenu_r9.separator);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngForOf", submenu_r9.items);
  }
}
function Menu_div_0_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Menu_div_0_5_ng_template_0_Template, 3, 3, "ng-template", 10);
  }
  if (rf & 2) {
    const ctx_r4 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngForOf", ctx_r4.model);
  }
}
function Menu_div_0_6_ng_template_0_li_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "li", 13);
  }
  if (rf & 2) {
    const item_r37 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(1, _c6, item_r37.visible === false));
  }
}
function Menu_div_0_6_ng_template_0_li_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r44 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "li", 20);
    i0.ɵɵlistener("onMenuItemClick", function Menu_div_0_6_ng_template_0_li_1_Template_li_onMenuItemClick_0_listener($event) {
      i0.ɵɵrestoreView(_r44);
      const ctx_r43 = i0.ɵɵnextContext();
      const item_r37 = ctx_r43.$implicit;
      const i_r38 = ctx_r43.index;
      const ctx_r42 = i0.ɵɵnextContext(3);
      return i0.ɵɵresetView(ctx_r42.itemClick($event, ctx_r42.menuitemId(item_r37, ctx_r42.id, i_r38)));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r45 = i0.ɵɵnextContext();
    const item_r37 = ctx_r45.$implicit;
    const i_r38 = ctx_r45.index;
    const ctx_r40 = i0.ɵɵnextContext(3);
    i0.ɵɵclassMap(item_r37.styleClass);
    i0.ɵɵproperty("pMenuItemContent", item_r37)("itemTemplate", ctx_r40.itemTemplate)("ngClass", i0.ɵɵpureFunction3(13, _c8, item_r37.visible === false, ctx_r40.focusedOptionId() && ctx_r40.menuitemId(item_r37, ctx_r40.id, i_r38, ctx_r40.j) === ctx_r40.focusedOptionId(), ctx_r40.disabled(item_r37.disabled)))("ngStyle", item_r37.style)("tooltipOptions", item_r37.tooltipOptions);
    i0.ɵɵattribute("data-pc-section", "menuitem")("aria-label", ctx_r40.label(item_r37.label))("data-p-focused", ctx_r40.isItemFocused(ctx_r40.menuitemId(item_r37, ctx_r40.id, i_r38)))("data-p-disabled", ctx_r40.disabled(item_r37.disabled))("aria-disabled", ctx_r40.disabled(item_r37.disabled))("id", ctx_r40.menuitemId(item_r37, ctx_r40.id, i_r38));
  }
}
function Menu_div_0_6_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Menu_div_0_6_ng_template_0_li_0_Template, 1, 3, "li", 11)(1, Menu_div_0_6_ng_template_0_li_1_Template, 1, 17, "li", 19);
  }
  if (rf & 2) {
    const item_r37 = ctx.$implicit;
    i0.ɵɵproperty("ngIf", item_r37.separator);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", !item_r37.separator);
  }
}
function Menu_div_0_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Menu_div_0_6_ng_template_0_Template, 2, 2, "ng-template", 10);
  }
  if (rf & 2) {
    const ctx_r5 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngForOf", ctx_r5.model);
  }
}
function Menu_div_0_div_7_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function Menu_div_0_div_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 21);
    i0.ɵɵtemplate(1, Menu_div_0_div_7_ng_container_1_Template, 1, 0, "ng-container", 9);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r6 = i0.ɵɵnextContext(2);
    i0.ɵɵattribute("data-pc-section", "end");
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r6.endTemplate);
  }
}
const _c9 = a1 => ({
  "p-menu p-component": true,
  "p-menu-overlay": a1
});
const _c10 = (a0, a1) => ({
  showTransitionParams: a0,
  hideTransitionParams: a1
});
const _c11 = a1 => ({
  value: "visible",
  params: a1
});
function Menu_div_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r48 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 1, 2);
    i0.ɵɵlistener("click", function Menu_div_0_Template_div_click_0_listener($event) {
      i0.ɵɵrestoreView(_r48);
      const ctx_r47 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r47.onOverlayClick($event));
    })("@overlayAnimation.start", function Menu_div_0_Template_div_animation_overlayAnimation_start_0_listener($event) {
      i0.ɵɵrestoreView(_r48);
      const ctx_r49 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r49.onOverlayAnimationStart($event));
    })("@overlayAnimation.done", function Menu_div_0_Template_div_animation_overlayAnimation_done_0_listener($event) {
      i0.ɵɵrestoreView(_r48);
      const ctx_r50 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r50.onOverlayAnimationEnd($event));
    });
    i0.ɵɵtemplate(2, Menu_div_0_div_2_Template, 2, 2, "div", 3);
    i0.ɵɵelementStart(3, "ul", 4, 5);
    i0.ɵɵlistener("focus", function Menu_div_0_Template_ul_focus_3_listener($event) {
      i0.ɵɵrestoreView(_r48);
      const ctx_r51 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r51.onListFocus($event));
    })("blur", function Menu_div_0_Template_ul_blur_3_listener($event) {
      i0.ɵɵrestoreView(_r48);
      const ctx_r52 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r52.onListBlur($event));
    })("keydown", function Menu_div_0_Template_ul_keydown_3_listener($event) {
      i0.ɵɵrestoreView(_r48);
      const ctx_r53 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r53.onListKeyDown($event));
    });
    i0.ɵɵtemplate(5, Menu_div_0_5_Template, 1, 1, null, 6)(6, Menu_div_0_6_Template, 1, 1, null, 6);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(7, Menu_div_0_div_7_Template, 2, 2, "div", 7);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵclassMap(ctx_r0.styleClass);
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(18, _c9, ctx_r0.popup))("ngStyle", ctx_r0.style)("@overlayAnimation", i0.ɵɵpureFunction1(23, _c11, i0.ɵɵpureFunction2(20, _c10, ctx_r0.showTransitionOptions, ctx_r0.hideTransitionOptions)))("@.disabled", ctx_r0.popup !== true);
    i0.ɵɵattribute("data-pc-name", "menu")("id", ctx_r0.id);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", ctx_r0.startTemplate);
    i0.ɵɵadvance(1);
    i0.ɵɵattribute("id", ctx_r0.id + "_list")("tabindex", ctx_r0.getTabIndexValue())("data-pc-section", "menu")("aria-activedescendant", ctx_r0.activedescendant())("aria-label", ctx_r0.ariaLabel)("aria-labelledBy", ctx_r0.ariaLabelledBy);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", ctx_r0.hasSubMenu());
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", !ctx_r0.hasSubMenu());
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r0.endTemplate);
  }
}
class SafeHtmlPipe {
  platformId;
  sanitizer;
  constructor(platformId, sanitizer) {
    this.platformId = platformId;
    this.sanitizer = sanitizer;
  }
  transform(value) {
    if (!value || !isPlatformBrowser(this.platformId)) {
      return value;
    }
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
  static ɵfac = function SafeHtmlPipe_Factory(t) {
    return new (t || SafeHtmlPipe)(i0.ɵɵdirectiveInject(PLATFORM_ID, 16), i0.ɵɵdirectiveInject(i1.DomSanitizer, 16));
  };
  static ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
    name: "safeHtml",
    type: SafeHtmlPipe,
    pure: true
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SafeHtmlPipe, [{
    type: Pipe,
    args: [{
      name: 'safeHtml'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [PLATFORM_ID]
    }]
  }, {
    type: i1.DomSanitizer
  }], null);
})();
class MenuItemContent {
  item;
  itemTemplate;
  onMenuItemClick = new EventEmitter();
  menu;
  constructor(menu) {
    this.menu = menu;
  }
  onItemClick(event, item) {
    this.onMenuItemClick.emit({
      originalEvent: event,
      item
    });
  }
  static ɵfac = function MenuItemContent_Factory(t) {
    return new (t || MenuItemContent)(i0.ɵɵdirectiveInject(forwardRef(() => Menu)));
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: MenuItemContent,
    selectors: [["", "pMenuItemContent", ""]],
    hostAttrs: [1, "p-element"],
    inputs: {
      item: ["pMenuItemContent", "item"],
      itemTemplate: "itemTemplate"
    },
    outputs: {
      onMenuItemClick: "onMenuItemClick"
    },
    attrs: _c0,
    decls: 5,
    vars: 3,
    consts: [[1, "p-menuitem-content", 3, "click"], [4, "ngIf"], ["itemContent", ""], ["class", "p-menuitem-link", "pRipple", "", 3, "target", "ngClass", 4, "ngIf"], ["routerLinkActive", "p-menuitem-link-active", "class", "p-menuitem-link", "pRipple", "", 3, "routerLink", "queryParams", "routerLinkActiveOptions", "target", "ngClass", "fragment", "queryParamsHandling", "preserveFragment", "skipLocationChange", "replaceUrl", "state", 4, "ngIf"], ["pRipple", "", 1, "p-menuitem-link", 3, "target", "ngClass"], [4, "ngTemplateOutlet", "ngTemplateOutletContext"], ["routerLinkActive", "p-menuitem-link-active", "pRipple", "", 1, "p-menuitem-link", 3, "routerLink", "queryParams", "routerLinkActiveOptions", "target", "ngClass", "fragment", "queryParamsHandling", "preserveFragment", "skipLocationChange", "replaceUrl", "state"], ["class", "p-menuitem-icon", 3, "ngClass", "class", "ngStyle", 4, "ngIf"], ["class", "p-menuitem-text", 4, "ngIf", "ngIfElse"], ["htmlLabel", ""], ["class", "p-menuitem-badge", 3, "ngClass", 4, "ngIf"], [1, "p-menuitem-icon", 3, "ngClass", "ngStyle"], [1, "p-menuitem-text"], [1, "p-menuitem-text", 3, "innerHTML"], [1, "p-menuitem-badge", 3, "ngClass"]],
    template: function MenuItemContent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵlistener("click", function MenuItemContent_Template_div_click_0_listener($event) {
          return ctx.onItemClick($event, ctx.item);
        });
        i0.ɵɵtemplate(1, MenuItemContent_ng_container_1_Template, 3, 2, "ng-container", 1)(2, MenuItemContent_ng_container_2_Template, 2, 4, "ng-container", 1)(3, MenuItemContent_ng_template_3_Template, 5, 4, "ng-template", null, 2, i0.ɵɵtemplateRefExtractor);
        i0.ɵɵelementEnd();
      }
      if (rf & 2) {
        i0.ɵɵattribute("data-pc-section", "content");
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", !ctx.itemTemplate);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.itemTemplate);
      }
    },
    dependencies: [i2.NgClass, i2.NgIf, i2.NgTemplateOutlet, i2.NgStyle, i3.RouterLink, i3.RouterLinkActive, i4.Ripple, SafeHtmlPipe],
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MenuItemContent, [{
    type: Component,
    args: [{
      selector: '[pMenuItemContent]',
      template: `
        <div [attr.data-pc-section]="'content'" class="p-menuitem-content" (click)="onItemClick($event, item)">
            <ng-container *ngIf="!itemTemplate">
                <a
                    *ngIf="!item?.routerLink"
                    [attr.title]="item.title"
                    [attr.href]="item.url || null"
                    [attr.data-automationid]="item.automationId"
                    [attr.tabindex]="-1"
                    [attr.data-pc-section]="'action'"
                    class="p-menuitem-link"
                    [target]="item.target"
                    [ngClass]="{ 'p-disabled': item.disabled }"
                    pRipple
                >
                    <ng-container *ngTemplateOutlet="itemContent; context: { $implicit: item }"></ng-container>
                </a>
                <a
                    *ngIf="item?.routerLink"
                    [routerLink]="item.routerLink"
                    [attr.data-automationid]="item.automationId"
                    [attr.tabindex]="-1"
                    [attr.data-pc-section]="'action'"
                    [attr.title]="item.title"
                    [queryParams]="item.queryParams"
                    routerLinkActive="p-menuitem-link-active"
                    [routerLinkActiveOptions]="item.routerLinkActiveOptions || { exact: false }"
                    class="p-menuitem-link"
                    [target]="item.target"
                    [ngClass]="{ 'p-disabled': item.disabled }"
                    [fragment]="item.fragment"
                    [queryParamsHandling]="item.queryParamsHandling"
                    [preserveFragment]="item.preserveFragment"
                    [skipLocationChange]="item.skipLocationChange"
                    [replaceUrl]="item.replaceUrl"
                    [state]="item.state"
                    pRipple
                >
                    <ng-container *ngTemplateOutlet="itemContent; context: { $implicit: item }"></ng-container>
                </a>
            </ng-container>

            <ng-container *ngIf="itemTemplate">
                <ng-template *ngTemplateOutlet="itemTemplate; context: { $implicit: item }"></ng-template>
            </ng-container>

            <ng-template #itemContent>
                <span class="p-menuitem-icon" *ngIf="item.icon" [ngClass]="item.icon" [class]="item.iconClass" [ngStyle]="item.iconStyle"></span>
                <span class="p-menuitem-text" *ngIf="item.escape !== false; else htmlLabel">{{ item.label }}</span>
                <ng-template #htmlLabel><span class="p-menuitem-text" [innerHTML]="item.label | safeHtml"></span></ng-template>
                <span class="p-menuitem-badge" *ngIf="item.badge" [ngClass]="item.badgeStyleClass">{{ item.badge }}</span>
            </ng-template>
        </div>
    `,
      encapsulation: ViewEncapsulation.None,
      host: {
        class: 'p-element'
      }
    }]
  }], () => [{
    type: Menu,
    decorators: [{
      type: Inject,
      args: [forwardRef(() => Menu)]
    }]
  }], {
    item: [{
      type: Input,
      args: ['pMenuItemContent']
    }],
    itemTemplate: [{
      type: Input
    }],
    onMenuItemClick: [{
      type: Output
    }]
  });
})();
/**
 * Menu is a navigation / command component that supports dynamic and static positioning.
 * @group Components
 */
class Menu {
  document;
  platformId;
  el;
  renderer;
  cd;
  config;
  overlayService;
  /**
   * An array of menuitems.
   * @group Props
   */
  model;
  /**
   * Defines if menu would displayed as a popup.
   * @group Props
   */
  popup;
  /**
   * Inline style of the component.
   * @group Props
   */
  style;
  /**
   * Style class of the component.
   * @group Props
   */
  styleClass;
  /**
   * Target element to attach the overlay, valid values are "body" or a local ng-template variable of another element (note: use binding with brackets for template variables, e.g. [appendTo]="mydiv" for a div element having #mydiv as variable name).
   * @group Props
   */
  appendTo;
  /**
   * Whether to automatically manage layering.
   * @group Props
   */
  autoZIndex = true;
  /**
   * Base zIndex value to use in layering.
   * @group Props
   */
  baseZIndex = 0;
  /**
   * Transition options of the show animation.
   * @group Props
   */
  showTransitionOptions = '.12s cubic-bezier(0, 0, 0.2, 1)';
  /**
   * Transition options of the hide animation.
   * @group Props
   */
  hideTransitionOptions = '.1s linear';
  /**
   * Defines a string value that labels an interactive element.
   * @group Props
   */
  ariaLabel;
  /**
   * Identifier of the underlying input element.
   * @group Props
   */
  ariaLabelledBy;
  /**
   * Current id state as a string.
   * @group Props
   */
  id;
  /**
   * Index of the element in tabbing order.
   * @group Props
   */
  tabindex = 0;
  /**
   * Callback to invoke when overlay menu is shown.
   * @group Emits
   */
  onShow = new EventEmitter();
  /**
   * Callback to invoke when overlay menu is hidden.
   * @group Emits
   */
  onHide = new EventEmitter();
  /**
   * Callback to invoke when the list loses focus.
   * @param {Event} event - blur event.
   * @group Emits
   */
  onBlur = new EventEmitter();
  /**
   * Callback to invoke when the list receives focus.
   * @param {Event} event - focus event.
   * @group Emits
   */
  onFocus = new EventEmitter();
  listViewChild;
  containerViewChild;
  templates;
  startTemplate;
  endTemplate;
  itemTemplate;
  submenuHeaderTemplate;
  container;
  scrollHandler;
  documentClickListener;
  documentResizeListener;
  preventDocumentDefault;
  target;
  visible;
  focusedOptionId = computed(() => {
    return this.focusedOptionIndex() !== -1 ? this.focusedOptionIndex() : null;
  });
  focusedOptionIndex = signal(-1);
  selectedOptionIndex = signal(-1);
  focused = false;
  overlayVisible = false;
  relativeAlign;
  constructor(document, platformId, el, renderer, cd, config, overlayService) {
    this.document = document;
    this.platformId = platformId;
    this.el = el;
    this.renderer = renderer;
    this.cd = cd;
    this.config = config;
    this.overlayService = overlayService;
    this.id = this.id || UniqueComponentId();
  }
  /**
   * Toggles the visibility of the popup menu.
   * @param {Event} event - Browser event.
   * @group Method
   */
  toggle(event) {
    if (this.visible) this.hide();else this.show(event);
    this.preventDocumentDefault = true;
  }
  /**
   * Displays the popup menu.
   * @param {Event} event - Browser event.
   * @group Method
   */
  show(event) {
    if (this.visible && this.target !== event.currentTarget) {
      this.hide();
    }
    this.target = event.currentTarget;
    this.relativeAlign = event.relativeAlign;
    this.visible = true;
    this.preventDocumentDefault = true;
    this.overlayVisible = true;
    this.cd.detectChanges();
  }
  ngOnInit() {
    if (!this.popup) {
      this.bindDocumentClickListener();
    }
  }
  ngAfterContentInit() {
    this.templates?.forEach(item => {
      switch (item.getType()) {
        case 'start':
          this.startTemplate = item.template;
          break;
        case 'end':
          this.endTemplate = item.template;
          break;
        case 'itemTemplate':
          this.itemTemplate = item.template;
          break;
        case 'submenuheader':
          this.submenuHeaderTemplate = item.template;
          break;
        default:
          this.itemTemplate = item.template;
          break;
      }
    });
  }
  getTabIndexValue() {
    return this.tabindex !== undefined ? this.tabindex.toString() : null;
  }
  onOverlayAnimationStart(event) {
    switch (event.toState) {
      case 'visible':
        if (this.popup) {
          this.container = event.element;
          this.moveOnTop();
          this.onShow.emit({});
          this.appendOverlay();
          this.alignOverlay();
          this.bindDocumentClickListener();
          this.bindDocumentResizeListener();
          this.bindScrollListener();
          DomHandler.focus(this.listViewChild.nativeElement);
          this.preventDocumentDefault = true;
        }
        break;
      case 'void':
        this.onOverlayHide();
        this.onHide.emit({});
        break;
    }
  }
  onOverlayAnimationEnd(event) {
    switch (event.toState) {
      case 'void':
        if (this.autoZIndex) {
          ZIndexUtils.clear(event.element);
        }
        break;
    }
  }
  alignOverlay() {
    if (this.relativeAlign) DomHandler.relativePosition(this.container, this.target);else DomHandler.absolutePosition(this.container, this.target);
  }
  appendOverlay() {
    if (this.appendTo) {
      if (this.appendTo === 'body') this.renderer.appendChild(this.document.body, this.container);else DomHandler.appendChild(this.container, this.appendTo);
    }
  }
  restoreOverlayAppend() {
    if (this.container && this.appendTo) {
      this.renderer.appendChild(this.el.nativeElement, this.container);
    }
  }
  moveOnTop() {
    if (this.autoZIndex) {
      ZIndexUtils.set('menu', this.container, this.baseZIndex + this.config.zIndex.menu);
    }
  }
  /**
   * Hides the popup menu.
   * @group Method
   */
  hide() {
    this.visible = false;
    this.relativeAlign = false;
    this.cd.detectChanges();
  }
  onWindowResize() {
    if (this.visible && !DomHandler.isTouchDevice()) {
      this.hide();
    }
  }
  menuitemId(item, id, index, childIndex) {
    return item?.id ?? `${id}_${index}${childIndex !== undefined ? '_' + childIndex : ''}`;
  }
  isItemFocused(id) {
    return this.focusedOptionId() === id;
  }
  label(label) {
    return typeof label === 'function' ? label() : label;
  }
  disabled(disabled) {
    return typeof disabled === 'function' ? disabled() : typeof disabled === 'undefined' ? false : disabled;
  }
  activedescendant() {
    return this.focused ? this.focusedOptionId() : undefined;
  }
  onListFocus(event) {
    if (!this.focused) {
      this.focused = true;
      this.onFocus.emit(event);
    }
  }
  onListBlur(event) {
    if (this.focused) {
      this.focused = false;
      this.changeFocusedOptionIndex(-1);
      this.selectedOptionIndex.set(-1);
      this.focusedOptionIndex.set(-1);
      this.onBlur.emit(event);
    }
  }
  onListKeyDown(event) {
    switch (event.code) {
      case 'ArrowDown':
        this.onArrowDownKey(event);
        break;
      case 'ArrowUp':
        this.onArrowUpKey(event);
        break;
      case 'Home':
        this.onHomeKey(event);
        break;
      case 'End':
        this.onEndKey(event);
        break;
      case 'Enter':
        this.onEnterKey(event);
        break;
      case 'NumpadEnter':
        this.onEnterKey(event);
        break;
      case 'Space':
        this.onSpaceKey(event);
        break;
      case 'Escape':
      case 'Tab':
        if (this.popup) {
          DomHandler.focus(this.target);
          this.hide();
        }
        this.overlayVisible && this.hide();
        break;
      default:
        break;
    }
  }
  onArrowDownKey(event) {
    const optionIndex = this.findNextOptionIndex(this.focusedOptionIndex());
    this.changeFocusedOptionIndex(optionIndex);
    event.preventDefault();
  }
  onArrowUpKey(event) {
    if (event.altKey && this.popup) {
      DomHandler.focus(this.target);
      this.hide();
      event.preventDefault();
    } else {
      const optionIndex = this.findPrevOptionIndex(this.focusedOptionIndex());
      this.changeFocusedOptionIndex(optionIndex);
      event.preventDefault();
    }
  }
  onHomeKey(event) {
    this.changeFocusedOptionIndex(0);
    event.preventDefault();
  }
  onEndKey(event) {
    this.changeFocusedOptionIndex(DomHandler.find(this.containerViewChild.nativeElement, 'li[data-pc-section="menuitem"][data-p-disabled="false"]').length - 1);
    event.preventDefault();
  }
  onEnterKey(event) {
    const element = DomHandler.findSingle(this.containerViewChild.nativeElement, `li[id="${`${this.focusedOptionIndex()}`}"]`);
    const anchorElement = element && DomHandler.findSingle(element, 'a');
    this.popup && DomHandler.focus(this.target);
    anchorElement ? anchorElement.click() : element && element.click();
    event.preventDefault();
  }
  onSpaceKey(event) {
    this.onEnterKey(event);
  }
  findNextOptionIndex(index) {
    const links = DomHandler.find(this.containerViewChild.nativeElement, 'li[data-pc-section="menuitem"][data-p-disabled="false"]');
    const matchedOptionIndex = [...links].findIndex(link => link.id === index);
    return matchedOptionIndex > -1 ? matchedOptionIndex + 1 : 0;
  }
  findPrevOptionIndex(index) {
    const links = DomHandler.find(this.containerViewChild.nativeElement, 'li[data-pc-section="menuitem"][data-p-disabled="false"]');
    const matchedOptionIndex = [...links].findIndex(link => link.id === index);
    return matchedOptionIndex > -1 ? matchedOptionIndex - 1 : 0;
  }
  changeFocusedOptionIndex(index) {
    const links = DomHandler.find(this.containerViewChild.nativeElement, 'li[data-pc-section="menuitem"][data-p-disabled="false"]');
    if (links.length > 0) {
      let order = index >= links.length ? links.length - 1 : index < 0 ? 0 : index;
      order > -1 && this.focusedOptionIndex.set(links[order].getAttribute('id'));
    }
  }
  itemClick(event, id) {
    const {
      originalEvent,
      item
    } = event;
    if (!this.focused) {
      this.focused = true;
      this.onFocus.emit();
    }
    if (item.disabled) {
      originalEvent.preventDefault();
      return;
    }
    if (!item.url && !item.routerLink) {
      originalEvent.preventDefault();
    }
    if (item.command) {
      item.command({
        originalEvent: originalEvent,
        item: item
      });
    }
    if (this.popup) {
      this.hide();
    }
    if (!this.popup && this.focusedOptionIndex() !== id) {
      this.focusedOptionIndex.set(id);
    }
  }
  onOverlayClick(event) {
    if (this.popup) {
      this.overlayService.add({
        originalEvent: event,
        target: this.el.nativeElement
      });
    }
    this.preventDocumentDefault = true;
  }
  bindDocumentClickListener() {
    if (!this.documentClickListener && isPlatformBrowser(this.platformId)) {
      const documentTarget = this.el ? this.el.nativeElement.ownerDocument : 'document';
      this.documentClickListener = this.renderer.listen(documentTarget, 'click', event => {
        const isOutsideContainer = this.containerViewChild?.nativeElement && !this.containerViewChild?.nativeElement.contains(event.target);
        const isOutsideTarget = !(this.target && (this.target === event.target || this.target.contains(event.target)));
        if (!this.popup && isOutsideContainer && isOutsideTarget) {
          this.onListBlur(event);
        }
        if (this.preventDocumentDefault && this.overlayVisible && isOutsideContainer && isOutsideTarget) {
          this.hide();
          this.preventDocumentDefault = false;
        }
      });
    }
  }
  unbindDocumentClickListener() {
    if (this.documentClickListener) {
      this.documentClickListener();
      this.documentClickListener = null;
    }
  }
  bindDocumentResizeListener() {
    if (!this.documentResizeListener && isPlatformBrowser(this.platformId)) {
      const window = this.document.defaultView;
      this.documentResizeListener = this.renderer.listen(window, 'resize', this.onWindowResize.bind(this));
    }
  }
  unbindDocumentResizeListener() {
    if (this.documentResizeListener) {
      this.documentResizeListener();
      this.documentResizeListener = null;
    }
  }
  bindScrollListener() {
    if (!this.scrollHandler && isPlatformBrowser(this.platformId)) {
      this.scrollHandler = new ConnectedOverlayScrollHandler(this.target, () => {
        if (this.visible) {
          this.hide();
        }
      });
    }
    this.scrollHandler?.bindScrollListener();
  }
  unbindScrollListener() {
    if (this.scrollHandler) {
      this.scrollHandler.unbindScrollListener();
    }
  }
  onOverlayHide() {
    this.unbindDocumentClickListener();
    this.unbindDocumentResizeListener();
    this.unbindScrollListener();
    this.preventDocumentDefault = false;
  }
  ngOnDestroy() {
    if (this.popup) {
      if (this.scrollHandler) {
        this.scrollHandler.destroy();
        this.scrollHandler = null;
      }
      if (this.container && this.autoZIndex) {
        ZIndexUtils.clear(this.container);
      }
      this.restoreOverlayAppend();
      this.onOverlayHide();
    }
    if (!this.popup) {
      this.unbindDocumentClickListener();
    }
  }
  hasSubMenu() {
    if (this.model) {
      for (var item of this.model) {
        if (item.items) {
          return true;
        }
      }
    }
    return false;
  }
  isItemHidden(item) {
    if (item.separator) {
      return item.visible === false || item.items && item.items.some(subitem => subitem.visible !== false);
    }
    return item.visible === false;
  }
  static ɵfac = function Menu_Factory(t) {
    return new (t || Menu)(i0.ɵɵdirectiveInject(DOCUMENT), i0.ɵɵdirectiveInject(PLATFORM_ID), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i5.PrimeNGConfig), i0.ɵɵdirectiveInject(i5.OverlayService));
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: Menu,
    selectors: [["p-menu"]],
    contentQueries: function Menu_ContentQueries(rf, ctx, dirIndex) {
      if (rf & 1) {
        i0.ɵɵcontentQuery(dirIndex, PrimeTemplate, 4);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.templates = _t);
      }
    },
    viewQuery: function Menu_Query(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵviewQuery(_c4, 5);
        i0.ɵɵviewQuery(_c5, 5);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.listViewChild = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.containerViewChild = _t.first);
      }
    },
    hostAttrs: [1, "p-element"],
    inputs: {
      model: "model",
      popup: ["popup", "popup", booleanAttribute],
      style: "style",
      styleClass: "styleClass",
      appendTo: "appendTo",
      autoZIndex: ["autoZIndex", "autoZIndex", booleanAttribute],
      baseZIndex: ["baseZIndex", "baseZIndex", numberAttribute],
      showTransitionOptions: "showTransitionOptions",
      hideTransitionOptions: "hideTransitionOptions",
      ariaLabel: "ariaLabel",
      ariaLabelledBy: "ariaLabelledBy",
      id: "id",
      tabindex: ["tabindex", "tabindex", numberAttribute]
    },
    outputs: {
      onShow: "onShow",
      onHide: "onHide",
      onBlur: "onBlur",
      onFocus: "onFocus"
    },
    features: [i0.ɵɵInputTransformsFeature],
    decls: 1,
    vars: 1,
    consts: [[3, "ngClass", "class", "ngStyle", "click", 4, "ngIf"], [3, "ngClass", "ngStyle", "click"], ["container", ""], ["class", "p-menu-start", 4, "ngIf"], ["role", "menu", 1, "p-menu-list", "p-reset", 3, "focus", "blur", "keydown"], ["list", ""], [4, "ngIf"], ["class", "p-menu-end", 4, "ngIf"], [1, "p-menu-start"], [4, "ngTemplateOutlet"], ["ngFor", "", 3, "ngForOf"], ["class", "p-menuitem-separator", "role", "separator", 3, "ngClass", 4, "ngIf"], ["class", "p-submenu-header", "pTooltip", "", "role", "none", 3, "ngClass", "tooltipOptions", 4, "ngIf"], ["role", "separator", 1, "p-menuitem-separator", 3, "ngClass"], ["pTooltip", "", "role", "none", 1, "p-submenu-header", 3, "ngClass", "tooltipOptions"], [4, "ngTemplateOutlet", "ngTemplateOutletContext"], [4, "ngIf", "ngIfElse"], ["htmlSubmenuLabel", ""], [3, "innerHTML"], ["class", "p-menuitem", "pTooltip", "", "role", "menuitem", 3, "pMenuItemContent", "itemTemplate", "ngClass", "ngStyle", "class", "tooltipOptions", "onMenuItemClick", 4, "ngIf"], ["pTooltip", "", "role", "menuitem", 1, "p-menuitem", 3, "pMenuItemContent", "itemTemplate", "ngClass", "ngStyle", "tooltipOptions", "onMenuItemClick"], [1, "p-menu-end"]],
    template: function Menu_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵtemplate(0, Menu_div_0_Template, 8, 25, "div", 0);
      }
      if (rf & 2) {
        i0.ɵɵproperty("ngIf", !ctx.popup || ctx.visible);
      }
    },
    dependencies: [i2.NgClass, i2.NgForOf, i2.NgIf, i2.NgTemplateOutlet, i2.NgStyle, i6.Tooltip, MenuItemContent, SafeHtmlPipe],
    styles: ["@layer primeng{.p-menu-overlay{position:absolute;top:0;left:0}.p-menu ul{margin:0;padding:0;list-style:none}.p-menu .p-submenu-header{align-items:center}.p-menu .p-menuitem-link{cursor:pointer;display:flex;align-items:center;text-decoration:none;overflow:hidden;position:relative}.p-menu .p-menuitem-text{line-height:1}}\n"],
    encapsulation: 2,
    data: {
      animation: [trigger('overlayAnimation', [transition(':enter', [style({
        opacity: 0,
        transform: 'scaleY(0.8)'
      }), animate('{{showTransitionParams}}')]), transition(':leave', [animate('{{hideTransitionParams}}', style({
        opacity: 0
      }))])])]
    },
    changeDetection: 0
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Menu, [{
    type: Component,
    args: [{
      selector: 'p-menu',
      template: `
        <div
            #container
            [ngClass]="{ 'p-menu p-component': true, 'p-menu-overlay': popup }"
            [class]="styleClass"
            [ngStyle]="style"
            *ngIf="!popup || visible"
            (click)="onOverlayClick($event)"
            [@overlayAnimation]="{ value: 'visible', params: { showTransitionParams: showTransitionOptions, hideTransitionParams: hideTransitionOptions } }"
            [@.disabled]="popup !== true"
            (@overlayAnimation.start)="onOverlayAnimationStart($event)"
            (@overlayAnimation.done)="onOverlayAnimationEnd($event)"
            [attr.data-pc-name]="'menu'"
            [attr.id]="id"
        >
            <div *ngIf="startTemplate" class="p-menu-start" [attr.data-pc-section]="'start'">
                <ng-container *ngTemplateOutlet="startTemplate"></ng-container>
            </div>
            <ul
                #list
                class="p-menu-list p-reset"
                role="menu"
                [attr.id]="id + '_list'"
                [attr.tabindex]="getTabIndexValue()"
                [attr.data-pc-section]="'menu'"
                [attr.aria-activedescendant]="activedescendant()"
                [attr.aria-label]="ariaLabel"
                [attr.aria-labelledBy]="ariaLabelledBy"
                (focus)="onListFocus($event)"
                (blur)="onListBlur($event)"
                (keydown)="onListKeyDown($event)"
            >
                <ng-template ngFor let-submenu let-i="index" [ngForOf]="model" *ngIf="hasSubMenu()">
                    <li class="p-menuitem-separator" *ngIf="submenu.separator" [ngClass]="{ 'p-hidden': submenu.visible === false }" role="separator"></li>
                    <li
                        class="p-submenu-header"
                        [attr.data-automationid]="submenu.automationId"
                        *ngIf="!submenu.separator"
                        [ngClass]="{ 'p-hidden': submenu.visible === false, flex: submenu.visible }"
                        pTooltip
                        [tooltipOptions]="submenu.tooltipOptions"
                        role="none"
                        [attr.id]="menuitemId(submenu, id, i)"
                    >
                        <ng-container *ngIf="!submenuHeaderTemplate">
                            <span *ngIf="submenu.escape !== false; else htmlSubmenuLabel">{{ submenu.label }}</span>
                            <ng-template #htmlSubmenuLabel><span [innerHTML]="submenu.label | safeHtml"></span></ng-template>
                        </ng-container>
                        <ng-container *ngTemplateOutlet="submenuHeaderTemplate; context: { $implicit: submenu }"></ng-container>
                    </li>
                    <ng-template ngFor let-item let-j="index" [ngForOf]="submenu.items">
                        <li class="p-menuitem-separator" *ngIf="item.separator" [ngClass]="{ 'p-hidden': item.visible === false || submenu.visible === false }" role="separator"></li>
                        <li
                            class="p-menuitem"
                            *ngIf="!item.separator"
                            [pMenuItemContent]="item"
                            [itemTemplate]="itemTemplate"
                            [ngClass]="{ 'p-hidden': item.visible === false || submenu.visible === false, 'p-focus': focusedOptionId() && menuitemId(item, id, i, j) === focusedOptionId(), 'p-disabled': disabled(item.disabled) }"
                            [ngStyle]="item.style"
                            [class]="item.styleClass"
                            (onMenuItemClick)="itemClick($event, menuitemId(item, id, i, j))"
                            pTooltip
                            [tooltipOptions]="item.tooltipOptions"
                            role="menuitem"
                            [attr.data-pc-section]="'menuitem'"
                            [attr.aria-label]="label(item.label)"
                            [attr.data-p-focused]="isItemFocused(menuitemId(item, id, i, j))"
                            [attr.data-p-disabled]="disabled(item.disabled)"
                            [attr.aria-disabled]="disabled(item.disabled)"
                            [attr.id]="menuitemId(item, id, i, j)"
                        ></li>
                    </ng-template>
                </ng-template>
                <ng-template ngFor let-item let-i="index" [ngForOf]="model" *ngIf="!hasSubMenu()">
                    <li class="p-menuitem-separator" *ngIf="item.separator" [ngClass]="{ 'p-hidden': item.visible === false }" role="separator"></li>
                    <li
                        class="p-menuitem"
                        *ngIf="!item.separator"
                        [pMenuItemContent]="item"
                        [itemTemplate]="itemTemplate"
                        [ngClass]="{ 'p-hidden': item.visible === false, 'p-focus': focusedOptionId() && menuitemId(item, id, i, j) === focusedOptionId(), 'p-disabled': disabled(item.disabled) }"
                        [ngStyle]="item.style"
                        [class]="item.styleClass"
                        (onMenuItemClick)="itemClick($event, menuitemId(item, id, i))"
                        pTooltip
                        [tooltipOptions]="item.tooltipOptions"
                        role="menuitem"
                        [attr.data-pc-section]="'menuitem'"
                        [attr.aria-label]="label(item.label)"
                        [attr.data-p-focused]="isItemFocused(menuitemId(item, id, i))"
                        [attr.data-p-disabled]="disabled(item.disabled)"
                        [attr.aria-disabled]="disabled(item.disabled)"
                        [attr.id]="menuitemId(item, id, i)"
                    ></li>
                </ng-template>
            </ul>
            <div *ngIf="endTemplate" class="p-menu-end" [attr.data-pc-section]="'end'">
                <ng-container *ngTemplateOutlet="endTemplate"></ng-container>
            </div>
        </div>
    `,
      animations: [trigger('overlayAnimation', [transition(':enter', [style({
        opacity: 0,
        transform: 'scaleY(0.8)'
      }), animate('{{showTransitionParams}}')]), transition(':leave', [animate('{{hideTransitionParams}}', style({
        opacity: 0
      }))])])],
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      host: {
        class: 'p-element'
      },
      styles: ["@layer primeng{.p-menu-overlay{position:absolute;top:0;left:0}.p-menu ul{margin:0;padding:0;list-style:none}.p-menu .p-submenu-header{align-items:center}.p-menu .p-menuitem-link{cursor:pointer;display:flex;align-items:center;text-decoration:none;overflow:hidden;position:relative}.p-menu .p-menuitem-text{line-height:1}}\n"]
    }]
  }], () => [{
    type: Document,
    decorators: [{
      type: Inject,
      args: [DOCUMENT]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [PLATFORM_ID]
    }]
  }, {
    type: i0.ElementRef
  }, {
    type: i0.Renderer2
  }, {
    type: i0.ChangeDetectorRef
  }, {
    type: i5.PrimeNGConfig
  }, {
    type: i5.OverlayService
  }], {
    model: [{
      type: Input
    }],
    popup: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    style: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }],
    appendTo: [{
      type: Input
    }],
    autoZIndex: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    baseZIndex: [{
      type: Input,
      args: [{
        transform: numberAttribute
      }]
    }],
    showTransitionOptions: [{
      type: Input
    }],
    hideTransitionOptions: [{
      type: Input
    }],
    ariaLabel: [{
      type: Input
    }],
    ariaLabelledBy: [{
      type: Input
    }],
    id: [{
      type: Input
    }],
    tabindex: [{
      type: Input,
      args: [{
        transform: numberAttribute
      }]
    }],
    onShow: [{
      type: Output
    }],
    onHide: [{
      type: Output
    }],
    onBlur: [{
      type: Output
    }],
    onFocus: [{
      type: Output
    }],
    listViewChild: [{
      type: ViewChild,
      args: ['list']
    }],
    containerViewChild: [{
      type: ViewChild,
      args: ['container']
    }],
    templates: [{
      type: ContentChildren,
      args: [PrimeTemplate]
    }]
  });
})();
class MenuModule {
  static ɵfac = function MenuModule_Factory(t) {
    return new (t || MenuModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: MenuModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule, RouterModule, RippleModule, TooltipModule, SharedModule, RouterModule, TooltipModule, SharedModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MenuModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, RouterModule, RippleModule, TooltipModule, SharedModule],
      exports: [Menu, RouterModule, TooltipModule, SharedModule],
      declarations: [Menu, MenuItemContent, SafeHtmlPipe]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { Menu, MenuItemContent, MenuModule, SafeHtmlPipe };
